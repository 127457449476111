import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://439155614c63e7262f6c45a05118a3f3@o4508173413122048.ingest.us.sentry.io/4508932655480832",
});

ReactDOM.render(
  <React.StrictMode>
    {" "}
    <App />{" "}
  </React.StrictMode>,
  document.getElementById("root")
);
